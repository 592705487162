<template>
    <div>
        <div class="dynamic-audience-selector">
            <icon
                class="mr-5"
                :name="platform.icon"
                size="20"
                color="#8F9EA6" />
            <div class="radio-group-container">
                <div class="radio-group-container">
                    <div
                        v-for="(option, index) in platformDefaults[platform.name]"
                        :key="index"
                        class="radio-tooltip mr-4">
                        <styled-radio
                            class="mr-1"
                            :value="radioValue"
                            :input-value="option"
                            :label="options[option]"
                            :name="platform.name + 'DynamicAudienceSelector'"
                            :disabled="shouldDisableRadio"
                            dot-style
                            inline
                            @input="radioValue = option" />
                        <ad-deployment-tooltip
                            :content-path="`adDeployment.dynamicAudienceConfiguration.tooltips.${platform.name}.${option}`"
                            :content-variables="{ dealerName: deploymentDealerName }" />
                    </div>
                </div>
            </div>
        </div>
        <audience-selector
            v-if="shouldShowCustomAudience"
            :channel="PLATFORM_PINTEREST"
            label="Select Custom Audiences"
            :show-icon="false"
            dynamic-deployment />
        <div
            v-if="!isLastPlatform"
            class="separator-container">
            <div class="separator" />
        </div>
    </div>
</template>

<script>
import Icon from '@/components/globals/Icon.vue';
import { PLATFORM_FACEBOOK, PLATFORM_PINTEREST, PLATFORM_SNAPCHAT, PLATFORM_TIKTOK } from '@/helpers/globals';
import StyledRadio from '@/components/globals/StyledRadio.vue';
import AdDeploymentTooltip from '@/components/ad-deployment/components/AdDeploymentTooltip.vue';
import AudienceSelector from '@/components/ad-deployment/steps/Audiences/AudienceSelector.vue';
import { mapMutations, mapState } from 'vuex';

export default {
    name: 'PlatformDynamicAudienceSelector',
    components: {
        AudienceSelector,
        AdDeploymentTooltip,
        Icon,
        StyledRadio,

    },
    props: {
        platform: {
            type: Object,
            required: true,
        },
        isLastPlatform: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            options: {
                automatedByChannel: 'Automated by Channel',
                retargetingOnly: 'Retargeting Only',
                prospectingOnly: 'Prospecting Only',
                customAudience: 'Custom Audience',
            },
            platformDefaults: {
                [PLATFORM_FACEBOOK]: ['automatedByChannel', 'retargetingOnly', 'prospectingOnly'],
                [PLATFORM_PINTEREST]: ['automatedByChannel', 'retargetingOnly', 'customAudience'],
                [PLATFORM_TIKTOK]: ['automatedByChannel'],
                [PLATFORM_SNAPCHAT]: ['automatedByChannel', 'retargetingOnly', 'prospectingOnly'],
            },
            loading: false,
            PLATFORM_PINTEREST,
        };
    },
    computed: {
        ...mapState({
            deploymentDealerName: (state) => state.adDeployment.dealer_name
        }),
        radioValue: {
            get() {
                return this.$store.state.adDeployment.dynamic_audience_configuration.platform_specific[this.platform.name].radio_group_option;
            },
            set(value) {
                if (value !== 'automatedByChannel') {
                    this.$emit('set-radio-custom');
                }

                this.setAdDeploymentValue({
                    key: `dynamic_audience_configuration.platform_specific.${this.platform.name}.radio_group_option`,
                    value
                });
            }
        },
        shouldShowCustomAudience() {
            return this.platform.name === PLATFORM_PINTEREST && this.radioValue === 'customAudience';
        },
        shouldDisableRadio() {
            return this.platformDefaults[this.platform.name].length === 1;
        }
    },
    created() {
        this.$parent.$on('show-default-audience', () => {
            this.radioValue = 'automatedByChannel';
        });
    },
    methods: {
        ...mapMutations({
            setAdDeploymentValue: 'adDeployment/setAdDeploymentValue'
        }),
    }
};
</script>

<style lang="scss" scoped>
.dynamic-audience-selector {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    .radio-group-container {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .radio-tooltip {
            display: flex;

            label {
                margin: 0;
            }
        }
    }
}

.separator-container {
    display: flex;
    justify-items: center;
    align-items: center;
    padding: 10px;

    .separator {
        border-top: 1px solid #DEDEDE;
        width: 100%;
    }
}
</style>
