<template>
    <div
        v-intersect="onIntersect"
        class="audience-wrapper"
        :class="{
            'dynamic-deployment': dynamicDeployment
        }">
        <div
            class="single-audience mb-4">
            <div
                v-if="showIcon"
                class="single-icon">
                <icon
                    :name="channel === 'facebook' ? 'meta' : channel"
                    color="#8F9EA6"
                    size="25" />
            </div>
            <div class="fields">
                <div class="field">
                    <v-autocomplete
                        v-model="selectedAudiences"
                        :items="availableAudiences"
                        :item-disabled="disableLoadingItem"
                        :return-object="true"
                        item-text="name"
                        item-value="id"
                        :label="label"
                        :loading="loading"
                        chips
                        multiple
                        required
                        class="styled-field ad-deployment-combo"
                        @change="selectAudience">
                        <template
                            #item="{item}"
                            #append-item>
                            <div
                                v-if="item.lazyLoading"
                                v-intersect="onNextPage">
                                ...Loading
                            </div>
                            <div
                                v-else-if="item.available"
                                class="option">
                                <div class="status">
                                    <div />
                                </div>
                                <div class="option-text">
                                    <div>{{ item.name }}</div>
                                    <div class="option-desc">
                                        {{ item.id }} - {{ item.description }}
                                    </div>
                                </div>
                            </div>
                            <div
                                v-else
                                class="option"
                                @click.stop.prevent>
                                <div class="not-available">
                                    <div class="status">
                                        <div />
                                    </div>
                                    <div class="option-text">
                                        <div>{{ item.name }}</div>
                                        <div class="option-desc">
                                            {{ item.id }} - {{ item.description }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </template>
                        <template #selection="{ attrs, item, parent, selected }">
                            <v-chip
                                v-if="item === Object(item)"
                                v-bind="attrs"
                                :selected="selected"
                                small>
                                <span class="pr-2">
                                    {{ item.name }}
                                </span>
                                <v-icon
                                    small
                                    @click="parent.selectItem(item)">
                                    close
                                </v-icon>
                            </v-chip>
                        </template>
                    </v-autocomplete>
                </div>
                <div class="icons">
                    <action-button
                        size="24"
                        icon="outbound-user"
                        target="_blank"
                        :href="outboundLink">
                        <span class="nowrap">New Audience</span>
                    </action-button>
                    <action-button
                        class="ml-4"
                        size="24"
                        icon="refresh"
                        @click="getAudiencesFromApi">
                        Refresh
                    </action-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Icon from '@/components/globals/Icon';
import ActionButton from '@/components/globals/ActionButton';
import { mapState, mapGetters, mapMutations } from 'vuex';
import { PLATFORM_FACEBOOK, PLATFORM_PINTEREST, PLATFORM_SNAPCHAT, PLATFORM_TIKTOK } from '@/helpers/globals';

export default {
    components: {
        Icon,
        ActionButton
    },
    props: {
        channel: {
            type: String,
            required: true
        },
        label: {
            type: String,
            default: 'Select...'
        },
        showIcon: {
            type: Boolean,
            default: true
        },
        dynamicDeployment: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            loading: false,
            selectedAudiences: [],
            availableAudiences: [],
            nextPage: null,
            hasIntersected: false,
            lazyLoadingItem: {
                lazyLoading: true,
            },
        };
    },
    computed: {
        ...mapState({
            platformAudiencesInStore(state) {
                return state.adDeployment.audience.platform_specific[this.channel].existing_audiences;
            },
            currentDealer: state => state.dealer.currentDealer
        }),
        ...mapGetters([
           'dealerPlatforms'
        ]),
        outboundLink() {
            if (this.channel === PLATFORM_FACEBOOK) {
                return `https://business.facebook.com/adsmanager/audiences?act=${this.getChannelAdAccountId()}&business_id=${this.currentDealer.agency.facebook_business_id}&global_scope_id=${this.currentDealer.agency.facebook_business_id}&tool=AUDIENCES`;
            } else if (this.channel === PLATFORM_SNAPCHAT) {
                return `https://ads.snapchat.com/${this.getChannelAdAccountId()}/audiences`;
            } else if (this.channel === PLATFORM_TIKTOK) {
                return `https://ads.tiktok.com/i18n_dmp/adver/main?aadvid=${this.getChannelAdAccountId()}`;
            } else if (this.channel === PLATFORM_PINTEREST) {
                return `https://ads.pinterest.com/advertiser/${this.getChannelAdAccountId()}/audiences`;
            } else {
                return '';
            }
        },
    },
    created() {
        this.getAudiencesFromApi();
        this.selectedAudiences = this.platformAudiencesInStore;
    },
    methods: {
        ...mapMutations({
            setAdDeploymentValue: 'adDeployment/setAdDeploymentValue'
        }),
        onNextPage() {
            if (this.nextPage) {
                this.getAudiencesFromApi();
            }
        },
        onIntersect() {
            if (!this.hasIntersected) {
                this.getAudiencesFromApi();
            }

            this.hasIntersected = true;
        },
        async getAudiencesFromApi() {
            try {
                this.loading = true;

                // todo add try catch and catch error messages in the select input
                const payload = {
                    dealerId: this.currentDealer.id,
                    platform: this.channel,
                    adAccountId: this.getChannelAdAccountId(),
                    params: {
                        page_size: 25,
                        page: this.nextPage,
                    }
                };

                let response = await this.$apiRepository.getDealerPlatformCustomAudiences(payload);

                const responses = this.channel === 'snapchat' ? response : response?.data;
                // If there's another page to load track it
                this.nextPage = responses?.meta?.next_page || null;
                const newEntries = responses?.data?.map(audience => {
                    return {
                        id: audience.external_id, // check in translators if this should be external_id or id
                        name: audience.name,
                        description: audience.description,
                        available: audience.is_available
                    };
                });

                // remove the lazyLoadingItem every time to make sure we only lazy load when reaching the last item on the list
                if(this.availableAudiences.length) {
                    this.availableAudiences.pop();
                }

                this.availableAudiences = [...this.availableAudiences, ...newEntries || []];
                if(this.availableAudiences.length >= 25) {
                    this.availableAudiences.push(this.lazyLoadingItem);
                }
            } catch(err) {
                console.log(err);
            } finally {
                this.loading = false;
            }

        },
        getChannelAdAccountId() {
            let channel = this.dealerPlatforms.find(dp => dp.name === this.channel);

            let adAccountId = '';

            if(this.channel === 'facebook') {
                adAccountId = this.currentDealer.facebook_ad_account_annotations.data[0].facebook_ad_account_id.replace('act_', '');
            } else {
                let adAccount = this.currentDealer.ad_accounts.data.find(cd => cd.platform_id === channel.id);

                if(adAccount) {
                    adAccountId = adAccount.external_ad_account_id;
                }
            }

            return adAccountId;
        },
        selectAudience() {
            if (this.dynamicDeployment) {
                this.setAdDeploymentValue({
                    key: `dynamic_audience_configuration.platform_specific.${this.channel}.custom_audiences`,
                    value: this.selectedAudiences
                });

                return;
            }

            // save the data to the store
            this.$store.commit('adDeployment/setExistingAudiences', { audiences: this.selectedAudiences, channel: this.channel });
        },
        disableLoadingItem(item) {
            return item.lazyLoading;
        }
    },
};
</script>


<style lang="scss" scoped>
.dynamic-deployment {
    display: flex;
    justify-content: center;

    .single-audience {
        width: 70%;
        height: 40px;
    }
}

.single-audience{
    display: flex;
    align-items: center;
    margin-bottom: 5px;
}
.single-icon{
    margin-right: 30px;
    margin-top: 5px;
}
.fields{
    width: 100%;
    display: flex;
}
.field{
    width: 100%;
    margin-right: 30px;
}
.channel-field{
    width: 40%;
}
.option{
    display: flex;
    align-items: center;
    height: 70px;
    margin-left: -15px;
}
.option-desc{
    font-size: 12px;
    font-style: italic;
    color: #ABABAB;
}
.not-available{
  position: absolute;
  display: flex;
  align-items: center;
  top: 0;
  bottom:0;
  left:0;
  right: 0;
  padding:10px 0;
  .status > div{
    background: #FF3A00;
  }
}
.status{
    width: 50px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    & > div{
        width: 15px;
        height: 15px;
        border-radius: 50%;
        background: #3EBF3F;
    }
}
.add{
    color: #00A4EB;
    border: 1px solid #00A4EB;
    border-radius: 5px;
    padding: 2px 20px;
    font-size: 14px;
    display: inline-block;
    cursor: pointer;
    font-weight: 600;
    transition: 0.3s;
    &:hover{
        color:white;
        background: #00A4EB;
    }
}
.icons{
    display: flex;
    margin-right: -15px;
}
.loading{
    text-align: center;
    .action-button{
        transform: translateY(5px);
    }
}
.nowrap{
    white-space: nowrap;
}
.next-page {
    height: 100px;
    width: 100%;
}
</style>
<style>
.v-list__tile{
    height: auto;
    min-height: 48px;
}
.ad-deployment-combo .v-chip__content > span{
    overflow: hidden;
    max-width: 310px;
}
</style>
