<template>
    <div class="wrapper">
        <div class="header">
            <p>Audience</p>
            <div class="radio-container">
                <div class="mr-4">
                    <div class="default">
                        <styled-radio
                            class="mr-2"
                            :value="radioValue"
                            input-value="default"
                            label="Automated By Channel"
                            name="defaultDynamicAudienceConfiguration"
                            dot-style
                            inline
                            @input="selectsRadioOption('default')" />
                        <span>(Recommended)</span>
                    </div>
                    <ad-deployment-tooltip content-path="adDeployment.dynamicAudienceConfiguration.tooltips.default" />
                </div>
                <div class="mr-4">
                    <styled-radio
                        class="mr-2"
                        :value="radioValue"
                        input-value="custom"
                        label="Customize"
                        name="customizeDynamicAudienceConfiguration"
                        dot-style
                        inline
                        @input="selectsRadioOption('custom')" />
                    <ad-deployment-tooltip content-path="adDeployment.dynamicAudienceConfiguration.tooltips.custom" />
                </div>
                <div
                    class="show-hide"
                    @click="showContent = !showContent">
                    <icon
                        :name="icon"
                        size="20"
                        color="#8F9EA6" />
                </div>
            </div>
        </div>
        <div
            v-show="showContent"
            class="body">
            <platform-dynamic-audience-selector
                v-for="(platform, index) in activePlatforms"
                :key="index"
                class="mb-4"
                :platform="platform"
                :is-last-platform="index === activePlatforms.length - 1"
                @set-radio-custom="selectsRadioOption('custom')" />
        </div>
    </div>
</template>
<script>
import AdDeploymentTooltip from '@/components/ad-deployment/components/AdDeploymentTooltip.vue';
import Icon from '@/components/globals/Icon.vue';
import StyledRadio from '@/components/globals/StyledRadio.vue';
import PlatformDynamicAudienceSelector
    from '@/components/ad-deployment/steps/DynamicDeployment/DynamicAudienceConfiguration/PlatformDynamicAudienceSelector/index.vue';
import { mapGetters, mapMutations } from 'vuex';

export default {
    name: 'DynamicAudienceConfigurationNonComplete',
    components: {
        AdDeploymentTooltip,
        Icon,
        StyledRadio,
        PlatformDynamicAudienceSelector
    },
    data() {
        return {
            showContent: false
        };
    },
    computed: {
        ...mapGetters({
            activePlatforms: 'adDeployment/activePlatforms'
        }),
        icon() {
            return this.showContent ? 'chevron-up' : 'chevron-down';
        },
        radioValue: {
            get() {
                return this.$store.state.adDeployment.dynamic_audience_configuration.radio_group_option;
            },
            set(value) {
                this.setAdDeploymentValue({
                    key: 'dynamic_audience_configuration.radio_group_option',
                    value
                });
            }
        },
    },
    created() {
        if (this.radioValue === 'custom') {
            this.showContent = true;
        }
    },
    methods: {
        ...mapMutations({
            setAdDeploymentValue: 'adDeployment/setAdDeploymentValue'
        }),
        selectsRadioOption(option) {
            if (option === 'default') {
                this.$emit('show-default-audience');
            }

            this.radioValue = option;
            this.showContent = true;
        }
    }
};
</script>

<style lang="scss" scoped>
.wrapper {
    background-color: $white;
    border: 1px solid #DEDEDE;

    .header {
        position: relative;
        padding: 26px 14px 26px 14px;
        border-bottom: 1px solid #DEDEDE;
        display: flex;
        flex-direction: row;
        align-items: center;

        p {
            font-size: 14px;
            color: #4A4A4A;
            font-weight: 600;
            margin: 0;
        }

        .radio-container {
            display: flex;
            margin-left: 78px;

            div {
                display: flex;
            }

            .default {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                margin-top: 11px;

                span {
                    font-size: 11px;
                    color: #9B9B9B;
                    margin-left: 20px;
                }
            }

            .inline {
                margin-bottom: 0;
            }
        }

        .show-hide {
            border-left: 1px solid #DEDEDE;
            top: 0;
            width: 71px;
            right: 0;
            bottom: 0;
            position:absolute;
            align-items: center;
            justify-content: center;

            &:hover {
                cursor: pointer;
            }
        }
    }

    .body {
        padding: 20px 23px 15px 23px;

        .product-group-selector {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }
}
</style>
